import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { lowerCase, upperCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

import iconArrowGold from '../img/iconarrow-gold.svg';
import iconArrowWhite from '../img/iconarrow-white.svg';
import iconArrowBack from '../img/iconarrow_back.svg';
import iconArrowNext from '../img/iconarrow_nav.svg';
import iconMouse from '../img/iconmouse.svg';

// eslint-disable-next-line
export const PortfolioPostTemplate = ({ style, backgroundcolor, gradientcolor, info, link, date, client, tags, title, helmet, featuredimage, data, pageId }) => {
  const featuredImage = data?.admin?.featuredimage;
  const featuredImage2 = data?.admin?.featuredimage2;

  const imagesLayout = data?.imagesLayout;

  const nonParallax = data?.admin?.nonparallaxpost;

  // GSAP
  gsap.registerPlugin(ScrollTrigger);

  let portfolioDetailRef = useRef();

  function init() {
    const q = gsap.utils.selector(portfolioDetailRef);

    let windowInnerHeight = typeof window !== `undefined` ? window.innerHeight : null;
    let windowInnerWidth = typeof window !== `undefined` ? window.innerWidth : null;

    let isDesktop = windowInnerWidth > 600;

    if (isDesktop && !nonParallax) {
      // PARALLAX HEADER IMAGE SECTION

      let [headerImage] = q('.portfolio-detail-header-image');

      headerImage.style.backgroundPosition = '50% 0px';

      gsap.to(headerImage, {
        backgroundPosition: `50% ${windowInnerHeight / 2}px`,
        ease: 'none',
        scrollTrigger: {
          trigger: headerImage,
          start: 'top top',
          end: 'bottom top',
          scrub: true,
        },
      });

      // PARALLAX FULL WIDTH SECTION

      let fullwidthImages = q('.portfolio-detail-fullwidth');

      fullwidthImages.forEach((section, i) => {
        // Do the parallax effect on each section

        section.style.backgroundPosition = `50% ${-windowInnerHeight / 2}px`;
        section.style.height = `${windowInnerHeight}px`;

        gsap.to(section, {
          backgroundPosition: `50% ${windowInnerHeight / 2}px`,
          ease: 'none',
          scrollTrigger: {
            trigger: section,
            scrub: true,
          },
        });
      });
    }

    // PARALLAX 3 VERTICAL SECTIONS

    let threeVertical1 = q('.portfolio-detail-image-3Vertical1');
    let threeVertical2 = q('.portfolio-detail-image-3Vertical2');
    let threeVertical3 = q('.portfolio-detail-image-3Vertical3');

    threeVertical1.forEach((threeVertical1) => {
      gsap.to(threeVertical1, {
        yPercent: `10`,
        ease: 'none',
        scrollTrigger: {
          trigger: threeVertical1,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 8,
        },
      });
    });

    threeVertical2.forEach((threeVertical2) => {
      gsap.to(threeVertical2, {
        yPercent: `-10`,
        ease: 'none',
        scrollTrigger: {
          trigger: threeVertical2,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 8,
        },
      });
    });

    threeVertical3.forEach((threeVertical3) => {
      gsap.to(threeVertical3, {
        yPercent: `10`,
        ease: 'none',
        scrollTrigger: {
          trigger: threeVertical3,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 8,
        },
      });
    });

    // PARALLAX 2 VERTICAL SECTIONS

    let twoVertical1 = q('.portfolio-detail-image-2Vertical1');
    let twoVertical2 = q('.portfolio-detail-image-2Vertical2');

    twoVertical1.forEach((twoVertical1) => {
      gsap.to(twoVertical1, {
        yPercent: `10`,
        ease: 'none',
        scrollTrigger: {
          trigger: twoVertical1,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 8,
        },
      });
    });

    twoVertical2.forEach((twoVertical2) => {
      gsap.to(twoVertical2, {
        yPercent: `-10`,
        ease: 'none',
        scrollTrigger: {
          trigger: twoVertical2,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 8,
        },
      });
    });

    // PARALLAX 2 HORIZONTAL SECTIONS

    let twoHorizontal1 = q('.portfolio-detail-image-2Horizontal1');
    let twoHorizontal2 = q('.portfolio-detail-image-2Horizontal2');

    twoHorizontal1.forEach((twoHorizontal1) => {
      gsap.to(twoHorizontal1, {
        xPercent: `10`,
        ease: 'none',
        scrollTrigger: {
          trigger: twoHorizontal1,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 8,
        },
      });
    });

    twoHorizontal2.forEach((twoHorizontal2) => {
      gsap.to(twoHorizontal2, {
        xPercent: `-10`,
        ease: 'none',
        scrollTrigger: {
          trigger: twoHorizontal2,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 8,
        },
      });
    });
  }
  
  // GSAP INIT
  useLayoutEffect(() => {

    init();

    ScrollTrigger.addEventListener('refreshInit', init); // on resize, things must be recalculated
    ScrollTrigger.addEventListener('refresh', init); // on resize, things must be recalculated
    ScrollTrigger.refresh();

    return () => {
      ScrollTrigger.removeEventListener('refreshInit', init);
      ScrollTrigger.removeEventListener('refresh', init);
      let triggers = ScrollTrigger.getAll();
      triggers.forEach((trigger) => {
        trigger.kill();
      });
    };
  }, []);

  function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'webm':
        return true;
      default:
        break;
    }
    return false;
  }

  return (
    <main className={`portfolio-detail portfolio-${style} portfolio-${title.replace(/\s/g, '')} ${nonParallax ? 'portfolio-nonparallax' : ''}`} id={pageId} style={{ backgroundColor: backgroundcolor }} ref={portfolioDetailRef}>
      <div
        className="portfolio-color-topfade"
        style={{
          background: `linear-gradient(180deg, ${data.admin.gradientcolor} 0%, transparent 100%)`,
        }}
      ></div>
      <div className="grain"></div>
      {helmet || ''}
      <section style={{ backgroundImage: `url(${featuredImage2})` }} alt={title || 'I AM Studio'} className="portfolio-detail-section portfolio-detail-fullwidth portfolio-detail-header-image">
        <img className="scroll-indicator" src={iconMouse} alt="I AM" style={{ width: '20px', zIndex: '999' }} />
        {isVideo(featuredImage2) && (
          <PreviewCompatibleImage
            imageInfo={{
              image: featuredImage2,
              backup: featuredImage,
              alt: `Featured Image`,
            }}
          />
        )}
      </section>
      <section className="portfolio-detail-section portfolio-details">
        <Link
          className="portfolio-detail-prev-navigation"
          to={`/${lowerCase(data.category)}/`}
          // to={`/tags/${kebabCase(tag)}/`}
        >
          <img className="portfolio-detail-prev-arrow" src={iconArrowBack} alt="Back to Projects" style={{ width: '45px' }} />
          <p className="">BACK</p>
        </Link>
        <h1 className="portfolio-detail-title">{title}</h1>
        <div className="portfolio-detail-container">
          <div className="portfolio-detail-client">
            <p>Client</p>
            <>{client}</>
          </div>
          <div className="portfolio-detail-date">
            <p>Date</p>
            <>{date}</>
          </div>
          <div className="portfolio-detail-info">
            <p>Info</p>
            <>{info}</>
            {data.link && (
              <a href={data.link} target="_blank" rel="noopener noreferrer" className="portfolio-detail-link">
                <p>Visit {client}</p>
                {style === 'color' ? (
                  <img className="iam-link-arrow" src={iconArrowWhite} alt="CTA Link Arrow in White" style={{ width: '20px' }} />
                ) : (
                  <img className="iam-link-arrow" src={iconArrowGold} alt="CTA Link Arrow in Gold" style={{ width: '20px' }} />
                )}
              </a>
            )}
          </div>
          <div className="portfolio-detail-tags">
            <p>Services</p>
            <>
              {tags && tags.length && (
                <div className="portfolio-detail-section portfolio-item-taglist">
                  <ul className="">
                    {tags.map((tag, index) => (
                      <li key={Math.random() * index}>
                        <p className="portfolio-detail-item-tag">{tag}</p>
                      </li>
                    ))}
                    {/* {tags.map((tag) => (
                      <li key={tag + `tag`}>
                        <Link
                          className="iam-link"
                          to={`/${lowerCase(data.category)}/`}
                          state={{ filter: tag }}
                          // to={`/tags/${kebabCase(tag)}/`}
                        >
                          {style === 'color' ? (
                            <img
                              className="iam-link-arrow"
                              src={iconArrowWhite}
                              alt={tag}
                              style={{ width: '20px' }}
                            />
                          ) : (
                            <img
                              className="iam-link-arrow"
                              src={iconArrowGold}
                              alt={tag}
                              style={{ width: '20px' }}
                            />
                          )}
                          <p>{tag}</p>
                        </Link>
                      </li>
                    ))} */}
                  </ul>
                </div>
              )}
            </>
          </div>
        </div>
      </section>

      {imagesLayout &&
        imagesLayout.map((layoutModule, index) => {
          switch (layoutModule.imagesModule) {
            case 'FullWidthImages':
              return layoutModule.images.map((image, index) => (
                <section key={Math.random() * index} style={{ backgroundImage: `url(${image.image})` }} alt={image.alt || 'I AM Studio'} className="portfolio-detail-fullwidth">
                  {isVideo(image.image) && (
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: image.image,
                        backup: image.image,
                        alt: `Featured Image`,
                      }}
                    />
                  )}
                </section>
              ));
            default:
              return (
                <section key={Math.random() * index} className={`portfolio-detail-images portfolio-detail-images-${layoutModule.imagesModule}`}>
                  {layoutModule.images.map((image, index) => (
                    <div
                      key={Math.random() * index}
                      // src={image.image}
                      style={{ backgroundImage: `url(${image.image})` }}
                      alt={image.alt || 'I AM Studio'}
                      className={`portfolio-detail-image portfolio-detail-image-${layoutModule.imagesModule}${index + 1}`}
                    />
                  ))}
                </section>
              );
          }
        })}
      <section className="portfolio-detail-section portfolio-detail-footer">
        <Link
          className="portfolio-detail-prev"
          to={`/${lowerCase(data.category)}/`}

          // to={`/tags/${kebabCase(tag)}/`}
        >
          <p>Projects</p>
          <img className="portfolio-detail-prev-arrow" src={iconArrowBack} alt="Back to Projects" style={{ width: '45px' }} />
        </Link>
        <Link
          className="portfolio-detail-next"
          to={`/${lowerCase(data.category)}/${data.admin.nextLink ? data.admin.nextLink : ''}`}
          // to={`/tags/${kebabCase(tag)}/`}
        >
          <p>Next</p>
          <img className="portfolio-detail-next-arrow" src={iconArrowNext} alt="Back to Projects" style={{ width: '45px' }} />
        </Link>
      </section>
    </main>
  );
};

PortfolioPostTemplate.propTypes = {
  style: PropTypes.any,
  backgroundcolor: PropTypes.any,
  info: PropTypes.string,
  client: PropTypes.string,
  date: PropTypes.any,
  title: PropTypes.string,
  helmet: PropTypes.object,
  data: PropTypes.any,
};

const PortfolioPost = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <PortfolioPostTemplate
        style={post.frontmatter.admin.style}
        backgroundcolor={post.frontmatter.admin.backgroundcolor}
        info={post.frontmatter.info}
        client={post.frontmatter.client}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate={post.frontmatter.meta?.title || `%s | ${upperCase(post.frontmatter.category)}`}>
            <title>{post.frontmatter.meta?.title || `${post.frontmatter.title}`}</title>
            <meta name="info" content={`${post.frontmatter.info}`} />
            <meta name="description" content={post.frontmatter.meta?.description || `${post.frontmatter.info}`} />
            <meta property="og:title" content={post.frontmatter.meta?.title || `${post.frontmatter.title}`} />
            <meta property="og:description" content={post.frontmatter.meta?.description || `${post.frontmatter.info}`} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.admin.featuredimage}
        data={post.frontmatter}
        pageId={post.id}
      />
    </Layout>
  );
};

PortfolioPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default PortfolioPost;

export const pageQuery = graphql`
  query PortfolioPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        meta {
          title
          description
        }
        admin {
          backgroundcolor
          gradientcolor
          featuredpost
          hiddenpost
          nonparallaxpost
          style
          featuredimage
          featuredimage2
          nextLink
        }
        category
        client
        date(formatString: "MMMM YYYY")
        info
        tags
        title
        link
        imagesLayout {
          imagesModule
          images {
            alt
            image
          }
        }
      }
    }
  }
`;

// "d1143379-d8cf-5757-85d0-7260d34fee0b"
